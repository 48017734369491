var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
    },
    [
      _c("c-tab", {
        ref: "nearmissTab",
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: _vm.popupParam,
                    tabParam: _vm.tabParam,
                    attachInfo1: _vm.attachInfo1,
                    attachInfo2: _vm.attachInfo2,
                    count: _vm.count,
                    saveCall: _vm.saveCall,
                  },
                  on: {
                    "update:saveCall": function ($event) {
                      _vm.saveCall = $event
                    },
                    "update:save-call": function ($event) {
                      _vm.saveCall = $event
                    },
                    changeStatus: _vm.changeStatus,
                    transInfo: _vm.transInfo,
                    closePopup: _vm.closePopup,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }