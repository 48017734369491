<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
    <c-tab
      ref="nearmissTab"
      type="vertical"
      :tabItems="tabItems"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :tabParam="tabParam"
          :attachInfo1="attachInfo1"
          :attachInfo2="attachInfo2"
          :count="count"
          :saveCall.sync="saveCall"
          @changeStatus="changeStatus"
          @transInfo="transInfo"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'near-register-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
  },
  data() {
    return {
      tabParam: {
        iimAccidentId: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: '',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        humanDamage: '',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        accidentVictimModelList: [],
        
        damageClassCd: null,
        woundCd: null,
      },
      saveCall: {
        saveCallData: '',
      },
      editable: true,
      completeUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      completeType: 'POST',
      isComplete: false,
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',

      splitter: 5,
      tab: 'nearRegisterInfo',
      tabHeight: '',
      height: 0,
      count: 0,
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      },
    };
  },
  computed: {
    disabled() {
      return this.tabParam.accidentStatusCd !== 'ISNC000001' || !this.popupParam.iimAccidentId
    },
    tabItems() {
      return [
        { name: 'nearRegisterInfo', icon: 'edit', label: '기본정보', component: () => import(`${'./nearRegisterInfo.vue'}`) },
        { name: 'nearRelationPicture', icon: 'camera_enhance', label: '관련 사진', component: () => import(`${'./nearRelationPicture.vue'}`) },
      ]
    },
  },
  watch: {
    height() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 580;
      numHeight = numHeight - 10
      console.log(this.tabHeight)
      this.tabHeight = numHeight + 'px';
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
    this.setSize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // url setting
      this.insertUrl = transactionConfig.sop.iim.accident.near.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.near.complete.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
    },
    setSize() {
      this.height = window.innerHeight - 100
    },
    changeStatus() {
      this.$emit('changeStatus')
    },
    transInfo(data) {
      this.$set(this.attachInfo1, 'taskKey', data.iimAccidentId)
      this.$set(this.attachInfo2, 'taskKey', data.iimAccidentId)
      
      this.saveCall.saveCallData = uid();
      this.tabParam = data;
      this.$emit('transInfo',data)
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    }
  }
};
</script>